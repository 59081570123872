<template>
  <v-main>
    <v-app-bar class="ukpl-main-navigation" v-if="currentUser" dense color="#0072ff" dark elevate-on-scroll>
      <div class="ukpl-main-navigation-container">
        <div class="navigation-left-wrap">
          <a href="https://www.ukpl.co.uk">
            <v-img src="@/assets/ukpl-logo-new.png" alt="UKPL logo" contain class="ukpl-nav-logo" />
          </a>
        </div>

        <v-spacer></v-spacer>

        <v-toolbar-items class="hidden-sm-and-down">
          <v-btn v-if="isAdmin" color="#0072ff" depressed to="/admin">admin</v-btn>
          <v-btn color="#0072ff" depressed to="/team"><v-icon class="mr-2">fa-users</v-icon>Teams</v-btn>
          <v-btn v-if="currentCaptain" color="#0072ff" depressed to="/roster"><v-icon
              class="mr-2">fa-list</v-icon>Roster</v-btn>
          <v-btn color="#0072ff" depressed to="/profile"><v-icon class="mr-2">fa-user</v-icon>Profile</v-btn>
          <v-btn color="#0072ff" depressed to="/disclaimer"><v-icon
              class="mr-2">fa-file-signature</v-icon>Disclaimer</v-btn>
          <v-btn color="#0072ff" depressed to="/idcard"><v-icon class="mr-2">far fa-id-card</v-icon>ID Card</v-btn>
          <v-btn color="#0072ff" depressed @click.native="logOut"><v-icon
              class="mr-2">fa-sign-out-alt</v-icon>LogOut</v-btn>
        </v-toolbar-items>
      </div>

      <v-toolbar-items class="hidden-md-and-up">
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      </v-toolbar-items>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item v-if="isAdmin" key="admin" to="/admin">
            <v-list-item-title>Admin</v-list-item-title>
          </v-list-item>
          <v-list-item key="team" to="/team">
            <v-list-item-icon><v-icon>fa-users</v-icon></v-list-item-icon>
            <v-list-item-title>Teams</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="currentCaptain" key="roster" to="/roster">
            <v-list-item-icon><v-icon>fa-list</v-icon></v-list-item-icon>
            <v-list-item-title>Roster</v-list-item-title>
          </v-list-item>
          <v-list-item key="profile" to="/profile">
            <v-list-item-icon><v-icon>fa-user</v-icon></v-list-item-icon>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
          <v-list-item key="disclaimer" to="/disclaimer">
            <v-list-item-icon><v-icon>fa-file-signature</v-icon></v-list-item-icon>
            <v-list-item-title>Disclaimer</v-list-item-title>
          </v-list-item>
          <v-list-item key="id-card" to="/idcard">
            <v-list-item-icon><v-icon>far fa-id-card</v-icon></v-list-item-icon>
            <v-list-item-title>ID Card</v-list-item-title>
          </v-list-item>
          <v-list-item @click.native="logOut" key="icon">
            <v-list-item-icon><v-icon>fa-sign-out-alt</v-icon></v-list-item-icon>
            <v-list-item-title>LogOut</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-sheet id="scrolling-techniques-7" class="ukpl-blue-card-container" height="100%">
      <v-container v-if="loading">
        <v-row class="fill-height" align-content="center" justify="center">
          <v-col class="text-subtitle-1 text-center" cols="12">
            Loading Roster
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-progress-linear color="blue" indeterminate rounded height="6"></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="elevation-12" fluid height="100%" v-if="!loading">
        <v-row dense>
          <v-col>
            <v-card>
              <v-card-text>
                <v-icon color="red"> mdi-close-circle </v-icon>
                Available. NOT ON ROSTER</v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card>
              <v-card-text>
                <v-icon color="green"> mdi-check-circle </v-icon>

                Selected. ON ROSTER</v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-card class="mx-auto">
          <v-toolbar color="indigo" dark>
            <v-toolbar-title>Choose Roster (Tap players to select/unselect)</v-toolbar-title>
          </v-toolbar>
          <v-list>
            <v-list-item-group v-model="selectedRoster" active-class="blue--text" multiple>
              <template v-for="(item, index) in items">
                <v-list-item :key="item.fullname" v-bind:disabled="locked">
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-icon v-if="!active" color="red">
                        mdi-close-circle
                      </v-icon>

                      <v-icon v-else color="green"> mdi-check-circle </v-icon>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title v-text="item.fullname"></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-avatar>
                      <v-img :src="calcPreviewImage(item.photo, item.photoType)" max-width="40"></v-img>
                    </v-list-item-avatar>
                  </template>
                </v-list-item>
                <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
        <v-card class="mx-auto">
          <v-toolbar color="indigo" dark>
            <v-toolbar-title>Guest Players</v-toolbar-title>
          </v-toolbar>
        </v-card>

        <v-card>
          <v-dialog v-model="dialog1" scrollable max-width="300px">
            <template v-slot:activator="{ on, attrs }">
              <v-card-title>
                <v-btn rounded color="blue accent-3" dark v-bind="attrs" v-on="on" v-if="!guests[0]">Add First Guest
                  Player</v-btn>
              </v-card-title>
            </template>
            <v-card>
              <v-card-title>
              <v-text-field class="mx-3" flat label="Search for player" prepend-inner-icon="mdi-search" solo-inverted
                v-model="search" clearable @click:clear="clearSearch"></v-text-field>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-list>
                  <v-list-item-group active-class="blue--text">
                    <template v-for="(item, index) in filteredGuests1()">
                      <v-list-item :key="item.fullname" v-bind:disabled="locked" @click="saveGuest1(item)">
                        <v-list-item-content>
                          <v-list-item-title v-text="item.fullname"></v-list-item-title>
                          <v-list-item-subtitle v-text="item.division"></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar>
                          <v-img :src="calcPreviewImage(item.photo, item.photoType)" max-width="40"></v-img>
                        </v-list-item-avatar>
                      </v-list-item>
                      <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
              <v-divider></v-divider>
            </v-card>
          </v-dialog>
          <v-list v-if="guests[0] != {}">
            <v-list-item v-if="guests.length >= 1 && guests[0] != {}" :key="guests[0].playerId" v-bind:disabled="locked"
              v-on:click="removeGuest1()">
              <v-list-item-content>
                <v-list-item-title v-text="guests[0].fullname"></v-list-item-title>
                <v-list-item-subtitle v-text="divwithboiler(guests[0])"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-avatar>
                <v-img :src="calcPreviewImage(guests[0].photo, guests[0].photoType)" max-width="40"></v-img>
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
        </v-card>

        <v-card>
          <v-dialog v-model="dialog2" scrollable max-width="300px">
            <template v-slot:activator="{ on, attrs }">
              <v-card-title>
                <v-btn rounded color="blue accent-3" dark v-bind:disabled="locked" v-bind="attrs" v-on="on"
                  v-if="guests[0] && !guests[1]">Add Second Guest Player</v-btn>
              </v-card-title>
            </template>
            <v-card>
              <v-card-title>
              <v-text-field class="mx-3" flat label="Search for player" prepend-inner-icon="mdi-search" solo-inverted
                v-model="search2" clearable @click:clear="clearSearch2"></v-text-field>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text v-if="filteredGuests2().length === 0">No Players Available</v-card-text>
              <v-card-text>
                <v-list>
                  <v-list-item-group active-class="blue--text">
                    <template v-for="(item, index) in filteredGuests2()">
                      <v-list-item :key="item.fullname" v-bind:disabled="locked" @click="saveGuest2(item)">
                        <v-list-item-content>
                          <v-list-item-title v-text="item.fullname"></v-list-item-title>
                          <v-list-item-subtitle v-text="item.division"></v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-avatar>
                          <v-img :src="calcPreviewImage(item.photo, item.photoType)" max-width="40"></v-img>
                        </v-list-item-avatar>
                      </v-list-item>
                      <v-divider v-if="index < items.length - 1" :key="index"></v-divider>
                    </template>
                  </v-list-item-group>
                </v-list>
              </v-card-text>
              <v-divider></v-divider>
            </v-card>
          </v-dialog>

          <v-list v-if="guests[1] != {}">
            <v-list-item v-if="guests.length == 2 && guests[1] != {}" :key="guests[1].playerId"
              v-on:click="removeGuest2()">
              <v-list-item-content>
                <v-list-item-title v-text="guests[1].fullname"></v-list-item-title>
                <v-list-item-subtitle v-text="divwithboiler(guests[1])"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-avatar>
                <v-img :src="calcPreviewImage(guests[1].photo, guests[1].photoType)" max-width="40"></v-img>
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
        </v-card>

        <v-card>
          <v-card-title>
            <v-btn rounded color="blue accent-3" dark @click="lock" v-bind:disabled="locked">Lock Roster for this
              round</v-btn>
          </v-card-title>
        </v-card>
        <v-card>
          <v-alert v-if="!locked" dense outlined type="error">
            You <strong>MUST</strong> lock you roster before the day you are
            playing.
          </v-alert>
          <v-alert v-if="locked" dense outlined type="error">
            <strong>Roster Locked</strong>
          </v-alert>
        </v-card>
        <v-snackbar v-model="lockvalidation" centered :timeout="5000" rounded="pill" color="red">
          You must have at least 5 players selected (green tick) on your roster
          (including guests) to lock your roster.
        </v-snackbar>
      </v-container>
    </v-sheet>
    <!--    <v-snackbar :timeout="2000" v-model="messageon" color="red accent-4" rounded="pill" elevation="24">
            {{ message }}
          </v-snackbar>
          <v-snackbar :timeout="2000" v-model="savesuccess" color="green accent-4" rounded="pill" elevation="24">
            Details Saved.
          </v-snackbar>-->
  </v-main>
</template>

<script>
import UserService from "../services/user.service";
import rosterService from "../services/roster.service";
//import Roster from "../model/roster";

export default {
  name: "Roster",
  components: {},
  data: () => ({
    bucket: "https://assets.ukpl.co.uk/",
    photobase: "images/",
    thumbbase: "thumbs/images/",
    selectedRoster: [],
    items: [],
    currentCaptain: false,
    drawer: false,
    locked: false,
    rosterlock: null,
    dialog1: false,
    selectedGuests: [],
    guests: [],
    dialog2: false,
    availableGuests: [],
    loading: true,
    lockvalidation: false,
    search: '',
    search2: '',
  }),
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isAdmin() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_ADMIN");
      }
      return false;
    },
  },
  methods: {
    filteredGuests1() {
      return this.availableGuests.filter(item => {
        if (!this.search && !this.guests[0]) {
          return this.availableGuests;
        }
        return (item.fullname.toLowerCase().includes(this.search.toLowerCase()));
      });
    },
    filteredGuests2() {
      return this.availableGuests.filter(item => {
        if (!this.search2 && !this.guests[0]) {
          return this.availableGuests;
        }
        if (item.fullname === this.guests[0].fullname) {
          return null;
        }
        return (item.fullname.toLowerCase().includes(this.search2.toLowerCase()));
      });
    },
    clearSearch () {
      this.search="";
    },
    clearSearch2 () {
      this.search2="";
    },
    divwithboiler(item) {
      return item.division + " - Click To Remove Guest";
    },
    saveGuest1(item) {
      console.log("saving guest 1 with item:" + item);
      console.log(item.fullname);
      this.guests[0] = item;
      rosterService.updateGuestRoster(this.guests);
      this.dialog1=false;
      this.search="";
      console.log("guests :" + this.guests);
    },
    removeGuest1() {
      this.guests.splice(0, 1);
      rosterService.updateGuestRoster(this.guests);
    },
    saveGuest2(item) {
      this.guests[1] = item;
      rosterService.updateGuestRoster(this.guests);
      this.dialog2=false;
      this.search2="";
    },
    removeGuest2() {
      this.guests.splice(1, 1);
      rosterService.updateGuestRoster(this.guests);
    },
    lock() {
      if (this.guests.length + this.selectedRoster < 4) {
        this.lockvalidation = true;
      } else {
        this.locked = true;
        rosterService.lockRoster();
      }
    },
    isCaptain() {
      UserService.isTeamCaptain().then(
        (response) => {
          if (response.data) {
            this.currentCaptain = true;
          } else {
            this.currentCaptain = false;
          }
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getAvailableGuests() {
      rosterService.getAvailableGuests().then(
        (response) => {
          this.availableGuests = response.data;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getRoster() {
      rosterService.getRoster().then(
        (response) => {
          response.data.players.forEach((player) => {
            if (!player.guest) {
              this.items.push(player);
            } else {
              this.selectedGuests.push(player);
              this.guests.push(player);
            }
          });
          //this.items = response.data.players;

          this.rosterlock = response.data.rosterLock;
          if (this.rosterlock != null) {
            this.locked = true;
          }
          this.items.forEach((item, index) => {
            if (item.selected) {
              this.selectedRoster.push(index);
            }
          });
          this.loading = false;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    calcPreviewImage(photoId, photoType) {
      if (photoId != undefined && photoType != undefined) {
        let filename = this.bucket + this.thumbbase + photoId;
        if (photoType === "image/jpeg") {
          filename = filename + ".jpg";
        } else if (photoType === "image/png") {
          filename = filename + ".png";
        } else {
          filename = "";
        }
        return filename;
      } else {
        return "";
      }
    },
  },
  created() {
    this.getRoster();
    this.isCaptain();
    this.getAvailableGuests();
  },
  updated() { },
  mounted() { },
  watch: {
    selectedRoster() {
      if (!this.locked) {
        var currentRoster = [];
        this.selectedRoster.forEach((element) => {
          currentRoster.push(this.items[element]);
        });
        // post currentRoster to back end service, to replace current roster for this team.
        rosterService.updateRoster(currentRoster);
      }
    },
  },
};
</script>
