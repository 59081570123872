import api from './api';

class RosterService {
  getRoster() {
    return api.get('secure/roster');
  }

  getAvailableGuests() {
    return api.get('secure/roster/guests');
  }

  updateRoster(roster) {
    return api.post('secure/roster', roster);
  }

  updateGuestRoster(roster) {
    return api.post('secure/roster/guests', roster);
  }

  lockRoster() {
    return api.post('secure/roster/lock');
  }
}

export default new RosterService();